@import "../node_modules/bootstrap/scss/functions";
@import "../node_modules/bootstrap/scss/variables";

/*
// CARNESPAÑA
$primary: #99b9d3;
$secondary:     #3f90d1;
$info:          $cyan;
$warning:       $yellow;
$danger:        $red;
$light:         #000000;
$dark:          $gray-900;
*/
/*

// Frutas Diaz
$primary:       #BBDDBB;
$secondary:     #000000;
$info:          $cyan;
$warning:       $yellow;
$danger:        $red;
$light:         #ffffff;
$dark:          $gray-900;
*/

// ENTREVETAS y A.MARTIN
/*
$primary:       #BBD9D7;
$secondary:     #000000;
$info:          $cyan;
$warning:       $yellow;
$danger:        $red;
$light:         #000000;
$dark:          $gray-900;

^/
*/

$primary:       #BBD9D7;
$secondary:     #000000;
$info:          $cyan;
$warning:       $yellow;
$danger:        $red;
$light:         #000000;
$dark:          $gray-900;


//futhermore it's necesary override the $theme-colors

$theme-colors: (
        "primary":    $primary,
        "secondary":  $secondary,
        "success":    $success,
        "info":       $info,
        "warning":    $warning,
        "danger":     $danger,
        "light":      $light,
        "dark":       $dark
);


@import '../node_modules/bootstrap/scss/bootstrap.scss';
$font-size-base: 1rem;

/*----------------------------------------------------------*/
/*----------------------------------------------------------*/
h1 {
  font-size: 1.37em !important;
}

h2 {
  font-size: 1.25em !important;
}
h3 {
  font-size: 1.125em !important;
}
h4 {
  font-size: 1em !important;
}

.btn-outline-primary:hover {
  color: var(--text-color) !important;
}
.btn-primary:hover {
  background-color: var(--primary-color) !important;
}

.btn-primary.active, .btn-primary:active, .open > .dropdown-toggle.btn-primary {
  /* STYLES GO HERE */
  background-color: var(--primary-color) !important;
}


.text-primary {
  color: var(--text-color)
}

.text-menu-secondary {
  color: var(--text-secondary) !important;
}

.hover-grey:hover {
  color: var(--text-secondary) !important;
  background-color: #e5e5e5 !important;
}

@font-face {
  font-family: var(--font), sans-serif;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}

h1 {
  font-family: var(--font), sans-serif !important;
}
h2 {
  font-family: var(--font), sans-serif !important;
}
h3 {
  font-family: var(--font), sans-serif !important;
}
h4 {
  font-family: var(--font), sans-serif !important;
}
h5 {
  font-family: var(--font), sans-serif !important;
}

.imagen-fondo {
  padding-left: 10% !important;
  padding-right: 10% !important;
  background-repeat:no-repeat;
  background-attachment: fixed;
  background-position: center top;
  background-size: 100% auto;
}

@media (max-width: 1000px) {
  .imagen-fondo {
  padding: 0 !important;
  }
}

.bg-white {
  background-color: var(--background-color) !important;
}


h5 span {
  background-color: var(--background-color) !important;
  padding: 0 10px;
  font-size: 15px;
}


.green-opacity {
  box-shadow: inset 0 0 0 1000px var(--primary-light-color);
}
